/// <reference path="../webviewer.d.ts" />
import WebViewer from '@pdftron/webviewer'

const queryString = window.location.search;
// console.log(queryString);

const urlParams = new URLSearchParams(queryString);

console.log(urlParams)

WebViewer({
    path: '/lib', // path to the PDFTron 'lib' folder on your server
    licenseKey: 'demo:1712428197442:7f1eee180300000000cc906140648fcfe563c3b86df2fdd5b51211ff01',
    initialDoc: `https://sysei-email.s3.amazonaws.com/${urlParams.get("filePath")}`,
    // initialDoc: 'https://sysei-email.s3.amazonaws.com/ADULTOS+I+COMPLETO.pdf',
}, document.getElementById('viewer'))
    .then(function (instance) {
        instance.UI.setLanguage('es');
        instance.UI.disableElements([
            "menuButton",
            "selectToolButton",
            "ribbons",
            "toolsHeader",
        ])
        // @ts-ignore
        const docViewer = instance.docViewer;
        // @ts-ignore
        const annotManager = instance.annotManager;
        // call methods from instance, docViewer and annotManager as needed

        // you can also access major namespaces from the instance as follows:
        // @ts-ignore
        const Tools = instance.Tools;
        // @ts-ignore
        const Annotations = instance.Annotations;
    });



